import React from "react";
import { Link } from "react-router-dom";

const Home = () => (
  <div className="p-10">
    <h1 className="text-green-800 text-4xl pb-8">Sportlink AAA Proxy demo</h1>
    <ul>
      <li>
        <Link to="/demo-oidc-react">
          Click here to go to the oidc-react demo page
        </Link>
      </li>
      <li>
        <Link to="/demo-keycloak">
          Click here to go to the keycloak-js demo page
        </Link>
      </li>
    </ul>
  </div>
);

export default Home;
