import React, { EffectCallback, useEffect, useRef, useState } from "react";
import Keycloak from "keycloak-js";

const authorityUrl = process.env.REACT_APP_AUTHORITY || "";

const keycloak = new Keycloak({
  url: authorityUrl
    .split("/")
    .slice(0, authorityUrl.split("/").length - 2)
    .join("/"),
  realm: authorityUrl.split("/")[authorityUrl.split("/").length - 1],
  clientId: process.env.REACT_APP_CLIENT_ID as string
});

export function useOnMountUnsafe(effect: EffectCallback) {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      effect();
    }
  }, []);
}

const DemoPageOidcReact = () => {
  const [kcInitialized, setKcInitialized] = useState<boolean>(false);
  useOnMountUnsafe(() => {
    keycloak
      .init({
        enableLogging: true,
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
      })
      .catch(err => console.error("Failed to initialize adapter:", err))
      .finally(() => setKcInitialized(true));
  });

  const login = () => keycloak.login();
  const logout = () => keycloak.logout();

  if (!kcInitialized) {
    return <div>loading</div>;
  }

  return (
    <div className="p-10">
      {!keycloak.authenticated && (
        <button onClick={() => login()}>Login</button>
      )}
      {keycloak.authenticated && (
        <button onClick={() => logout()}>Logout</button>
      )}
      <hr className="py-4" />

      <h3>Subject:</h3>
      <p style={{ overflow: "auto" }}>{keycloak.subject}</p>
      <button
        className="text-blue-700"
        onClick={() => navigator.clipboard.writeText(keycloak.subject || "")}
      >
        Copy
      </button>
      <hr className="py-4" />
      <h3>Token:</h3>
      <p style={{ overflow: "auto" }}>{keycloak.token}</p>
      <button
        className="text-blue-700"
        onClick={() => navigator.clipboard.writeText(keycloak.token || "")}
      >
        Copy
      </button>
      <hr className="py-4" />
      <h3>Refresh-token:</h3>
      <p style={{ overflow: "auto" }}>{keycloak.refreshToken}</p>
      <button
        className="text-blue-700"
        onClick={() =>
          navigator.clipboard.writeText(keycloak.refreshToken || "")
        }
      >
        Copy
      </button>
    </div>
  );
};

export default DemoPageOidcReact;
