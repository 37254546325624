import React from "react";
import { Link } from "react-router-dom";

const Nav = () => (
  <div className="top-0 w-full flex flex-wrap">
    <section className="x-auto">
      <nav className="flex justify-between bg-gray-200 text-blue-800 w-screen px-8 py-4 w-full items-center">
        <h1
          className="flex-none text-3xl font-bold font-heading"
          title={process.env.NODE_ENV}
        >
          OIDC React AUTH
        </h1>
        <div className="flex-1 flex items-center font-semibold font-heading space-x-12 px-8">
          <Link to="/" className="flex-none hover:text-blue-800">
            Home
          </Link>
          <Link to="/demo-oidc-react" className="flex-none hover:text-blue-800">
            Demo (oidc-react)
          </Link>
          <Link to="/demo-keycloak" className="flex-none hover:text-blue-800">
            Demo (keycloak-js)
          </Link>
        </div>
      </nav>
    </section>
  </div>
);

export default Nav;
