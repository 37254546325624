import React from "react";
import "./App.css";
import Nav from "./components/Nav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/HomePage";
import DemoPageOidcReact from "./pages/DemoPageOidcReact";
import DemoPageKeyCloak from "./pages/DemoPageKeyCloak";

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/demo-oidc-react" element={<DemoPageOidcReact />} />
        <Route path="/demo-keycloak" element={<DemoPageKeyCloak />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
