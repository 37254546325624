import React, { useEffect, useState } from "react";
import { AuthContextProps, AuthProvider, useAuth } from "oidc-react";

const DemoPageOidcReact = () => {
  const [personData, setPersonData] = useState<string>("");
  const [headerMethod, setHeaderMethod] = useState<string>("keycloak");
  const [customHeader, setCustomHeader] = useState<string>("");
  const [callDisabled, setCallDisabled] = useState<boolean>(false);
  const auth = useAuth();

  console.log("user:", auth.userData);

  useEffect(() => {
    if (headerMethod === "keycloak") {
      setCallDisabled(false);
    } else {
      setCallDisabled(customHeader.length === 0);
      setHeaderMethod("custom");
    }
  }, [headerMethod, customHeader]);

  async function memberPortalTest(auth: AuthContextProps) {
    const authHeader =
      headerMethod === "keycloak" ? auth.userData?.access_token : customHeader;
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authHeader}`,
        "X-Navajo-Version": `${process.env.REACT_APP_NAVAJO_VERSION}`,
        "X-Navajo-Instance": `${process.env.REACT_APP_NAVAJO_INSTANCE}` // Required by proxy
      }
      // body: ...
    };
    console.log("Fetch, options=", options);
    const userResponse = await fetch(
      `${process.env.REACT_APP_API_URL}`,
      options
    );
    const data = await userResponse.text();
    console.log("Received response:", data);
    setPersonData(data);
  }

  const onChangeHeaderMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaderMethod(event.target.value);
  };

  const onChangeCustomHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomHeader(event.target.value);
  };

  const handleClick = () => {
    if (!!auth.userData) {
      auth.userManager.signoutRedirect();
    } else {
      auth.signIn();
    }
  };

  return (
    <div className="p-10">
      <div className="flex-none items-center space-x-5 pb-8">
        <button
          type="button"
          className="text-blue-800"
          onClick={() => handleClick()}
        >
          {!!auth.userData ? (
            <>Logout ({auth.userData?.profile.name})</>
          ) : (
            <>Login</>
          )}
        </button>
      </div>

      <h1 className="text-black text-4xl pb-8">Welcome to the Demo Page</h1>
      {!auth.userData && <div>You are not logged in!</div>}
      {auth.userData && (
        <>
          <p>Hello {auth.userData?.profile.name}, you are logged in!</p>
          <br />
          <h2 className="text-2xl">User data</h2>
          <div className="px-8">
            <div>Username: {auth.userData?.profile?.preferred_username}</div>
            <div>Subject: {auth.userData?.profile?.sub}</div>
            <div>First name: {auth.userData?.profile?.given_name}</div>
            <div>Last name: {auth.userData?.profile?.family_name}</div>
            <div>E-mail: {auth.userData?.profile?.email}</div>
            <div>Keycloak clientId: {auth.userData?.profile?.aud}</div>
            <div>Token type: {auth.userData?.token_type}</div>
            <div>Refresh token</div>
            <div
              title={auth.userData?.refresh_token}
            >
              {auth.userData?.refresh_token}
            </div>
            <button
              className="text-blue-700 pb-3"
              onClick={() =>
                navigator.clipboard.writeText(
                  auth.userData?.refresh_token || ""
                )
              }
            >
              Copy to clipboard
            </button>

            <div>Access token</div>
            <div
              title={auth.userData?.access_token}
            >
              {auth.userData?.access_token}
            </div>
            <button
              className="text-blue-700 pb-3"
              onClick={() =>
                navigator.clipboard.writeText(auth.userData?.access_token || "")
              }
            >
              Copy to clipboard
            </button>
            <hr className="py-4" />

            <div>Token expires in</div>
            <div>
              {auth.userData?.expires_in} seconds
            </div>
          </div>

          <h2 className="text-2xl pt-8">Test Member portal</h2>
          <div className="px-8 grid grid-cols-5">
            <div>Request:</div>
            <div className="col-span-4 flex flex-col">
              <div onChange={onChangeHeaderMethod}>
                <div>
                  <input
                    id="headerKeycloak"
                    type="radio"
                    value="keycloak"
                    name="headerMethod"
                    checked={headerMethod === "keycloak"}
                    className="mr-4 checked:bg-blue-500"
                  />
                  <label htmlFor="headerKeycloak">
                    Use Keycloak Authorization header
                  </label>
                </div>
                <div className="flex">
                  <input
                    id="headerCustom"
                    type="radio"
                    value="custom"
                    name="headerMethod"
                    checked={headerMethod === "custom"}
                    className="mr-4 checked:bg-blue-500"
                  />
                  <label htmlFor="headerCustom">
                    Override Authorization header:&nbsp;
                  </label>
                  <input
                    type="text"
                    name="hdr"
                    value={customHeader}
                    placeholder="Paste custom header"
                    className="mx-2 w-96 border-2"
                    onChange={onChangeCustomHeader}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="w-48 text-sky-800 rounded-lg border-solid border-2 border-sky-800 mt-2 px-2 py-1 mr-4 mb-2 h-10 disabled:text-gray-400 disabled:border-gray-400"
                  onClick={() => memberPortalTest(auth)}
                  disabled={callDisabled}
                >
                  Call test endpoint
                </button>
                <p className="mt-4">({process.env.REACT_APP_API_URL})</p>
              </div>
            </div>

            {personData && (
              <>
                <div className="mt-4">Response:</div>
                <div className="mt-4 p-2 col-span-4 whitespace-pre break-all bg-indigo-100 h-48 font-mono overflow-auto">
                  {personData}
                </div>

                <div className="col-span-1" />
                <div className="col-span-4">
                  <button
                    type="button"
                    className="text-sky-800 rounded-lg border-solid border-2 border-sky-800 mt-2 px-2 py-1"
                    onClick={() => setPersonData("")}
                  >
                    Clear response
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const oidcConfig = {
  onSignIn: () => {
    // Redirect?
  },
  authority: process.env.REACT_APP_AUTHORITY,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  autoSignIn: false
};

export default () => {
  return (
    <AuthProvider {...oidcConfig}>
      <DemoPageOidcReact />
    </AuthProvider>
  );
};
